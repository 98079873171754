import React from 'react';
import './home.scss';
import Services from '../../v2/components/Services/Services';
import HomeBanner from '../../v2/components/HomeBanner/HomeBanner';

const Home = () => (
  <div className="home">
    <HomeBanner />
    <Services />
  </div>
);

export default Home;