import React from "react";
import './productsHeader.scss';
import { labels } from '../../v2/common/constants/constants';

const ProductsHeader = () => {
  return (
    <div className="products-header">
      <p className="products-header__details">
        {labels.productsHeaderTitle}
      </p>
      <img
        className="products-header__image"
        src={`${process.env.PUBLIC_URL}/images/productsLogo.png`}
        alt=""
      />
    </div>
  );
};

export default ProductsHeader;
