import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import "./app.scss";
import Navbar from './v2/components/Navbar/Navbar';
import Footer from './v2/components/Footer/Footer';
import Home from './pages/Home/Home';
import AboutUs from './pages/AboutUs/AboutUs';
import Products from './pages/Products/Products';

function App() {
  const location = useLocation();

  return (
    <div className="app">
      <Navbar currentPath={location.pathname} />
      <div className="app__content">
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/products">
            <Products />
          </Route>
          <Route exact path="/contact">
            <AboutUs />
          </Route>
          <Redirect from="*" to="/" />
        </Switch>
      </div>
      <Footer />
    </div>
  );
}

export default App;
