import React from 'react';
import './services.scss'
import {
  labels,
  servicesLabels,
} from '../../common/constants/constants';

const Services = () => (
  <div className="services">
    <div className="services__title">
      {labels.servicesTitle}
    </div>
    <div className="services__content">
      <div className="services__content__details">
        {servicesLabels.map((service) => (
          <div className="services__content__details__group">
            <div className="services__content__details__group__title">
              {service.title}
            </div>
            <div className="services__content__details__group__subtitle">
              {service.content}
            </div>
          </div>
        ))}
      </div>
      <div className="services__content__details__image-container">
        <img
          src={`${process.env.PUBLIC_URL}/images/design.png`}
          alt=""
        />
      </div>
    </div>
  </div>
);

export default Services;