export const paths = {
  home: '/',
  products: '/products',
  contact: '/contact',
};

export const labels = {
  mainTitle: 'Croitoria Cuti',
  home: 'Acasă',
  products: 'Produse',
  contact: 'Contact',
  location: 'Locație',
  homeTopTitle: 'Eleganță, calitate, viziune',
  homeTopSubtitle: 'Nu lăsa moda să pună stăpânire pe tine. Fii tu acela care decide ce ești, ce vrei să exprimi prin felul în care te îmbraci și modul în care trăiești.',
  servicesTitle: 'Gamă largă de servicii',
  productsHeaderTitle: 'Un costum potrivit este primul pas important, restul vine de la sine!',
  productsFiltersTitle: 'Inspiră-te din creațiile noastre',
  all: 'Toate',
  costumes: 'Costume',
  coats: 'Paltoane',
}

export const details = {
  workInterval: '08:00-17:00',
  phoneNumber: '0770 668 208',
  address: 'Str. Curtea Domneasca, Nr.4, Bl.7, Sc.A, 720004 Suceava',
  indications: 'În zona Colegiului Național Ștefan cel Mare Suceava',
  facebook: 'facebook.com/CroitoriaCuti',
  instagram: 'instagram.com/CroitoriaCuti',
  email: 'croitoriacuti@gmail.com',
};

export const servicesLabels = [
  {
    title: 'Croială',
    content: 'Croitoria Cuti oferă cele mai bune servicii cu cele mai performante mașini pentru a retușa, modifica, personaliza și cel mai important, pentru a crea.',
  },
  {
    title: 'Retuș',
    content: 'Alege o ținută în care să te simți confortabil și în largul tău, restul vine de la sine. Dar dacă dorești o modificare, aici trebuie să vii.',
  },
  {
    title: 'Design',
    content: 'Vă asteptăm cu drag pentru a da viață împreună ideilor dumneavoastră. Suntem deschiși, întelegători și dorim ca produsul final să fie așa cum vi l-ați imaginat.',
  }
];

export const contactLabels = {
  firstSectionTitle: 'O ținută simplă îți poate aduce succesul!',
  firstSentence: 'Croitoria Cuti oferă cele mai bune servicii, utilizând mașini performante și materiale de cea mai bună calitate. ',
  secondSentence: 'Firma noastră dispune de un personal profesionist, înțelegător și cel mai important, care se gândește la nevoile / sugestiile / preferințele clienților. ',
  thirdSentence: 'Croitoria reprezintă un spațiu unde creativitatea este evidențiată pentru realizarea costumelor dorite. De asemenea, îmbrăcămintea poate fi personalizată, modificată sau retușată, în funcție de cerințele clienților.',
  secondSectionTitle: 'Detalii suplimentare',
}

export const filters = {
  all: 'all',
  costumes: 'costumes',
  coats: 'coats',
};

export const products = [
  {
    id: 1,
    category: 'costumes',
    path: 'costumes/costume18.png',
  },{
    id: 2,
    category: 'costumes',
    path: 'costumes/costume16.png',
  },{
    id: 3,
    category: 'costumes',
    path: 'costumes/costume17.png',
  },{
    id: 4,
    category: 'costumes',
    path: 'costumes/costume13.png',
  },{
    id: 5,
    category: 'costumes',
    path: 'costumes/costume14.png',
  },{
    id: 6,
    category: 'costumes',
    path: 'costumes/costume15.png',
  },{
    id: 7,
    category: 'costumes',
    path: 'costumes/costume0.png',
  },{
    id: 8,
    category: 'costumes',
    path: 'costumes/costume5.png',
  },{
    id: 9,
    category: 'costumes',
    path: 'costumes/costume2.png',
  },{
    id: 10,
    category: 'costumes',
    path: 'costumes/costume3.png',
  },{
    id: 11,
    category: 'costumes',
    path: 'costumes/costume4.png',
  },{
    id: 12,
    category: 'costumes',
    path: 'costumes/costume1.png',
  },{
    id: 13,
    category: 'costumes',
    path: 'costumes/costume6.png',
  },{
    id: 14,
    category: 'costumes',
    path: 'costumes/costume7.png',
  },{
    id: 15,
    category: 'costumes',
    path: 'costumes/costume8.png',
  },{
    id: 16,
    category: 'costumes',
    path: 'costumes/costume9.png',
  },{
    id: 17,
    category: 'costumes',
    path: 'costumes/costume11.png',
  },{
    id: 18,
    category: 'costumes',
    path: 'costumes/costume12.png',
  },{
    id: 19,
    category: 'coats',
    path: 'coats/coat8.png',
  },{
    id: 20,
    category: 'coats',
    path: 'coats/coat9.png',
  },{
    id: 21,
    category: 'coats',
    path: 'coats/coat10.png',
  },{
    id: 22,
    category: 'coats',
    path: 'coats/coat11.png',
  },{
    id: 23,
    category: 'coats',
    path: 'coats/coat12.png',
  },{
    id: 24,
    category: 'coats',
    path: 'coats/coat13.png',
  },{
    id: 25,
    category: 'coats',
    path: 'coats/coat14.png',
  },{
    id: 26,
    category: 'coats',
    path: 'coats/coat15.png',
  },{
    id: 27,
    category: 'coats',
    path: 'coats/coat16.png',
  },{
    id: 28,
    category: 'coats',
    path: 'coats/coat0.png',
  },{
    id: 29,
    category: 'coats',
    path: 'coats/coat2.png',
  },{
    id: 30,
    category: 'coats',
    path: 'coats/coat3.png',
  },{
    id: 31,
    category: 'coats',
    path: 'coats/coat4.png',
  },{
    id: 32,
    category: 'coats',
    path: 'coats/coat5.png',
  },{
    id: 33,
    category: 'coats',
    path: 'coats/coat6.png',
  }
];
