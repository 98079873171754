import React from 'react';
import './homeBanner.scss';
import { labels } from '../../common/constants/constants';

const HomeBanner = () => (
  <div className="home-banner">
    <div className="home-banner__title">
      {labels.homeTopTitle}
    </div>
    <div className="home-banner__subtitle">
      {labels.homeTopSubtitle}
    </div>
  </div>
);

export default HomeBanner;