import React from 'react';
import './productsList.scss';

const ProductsList = (
  {
    products = [],
  }
) => (
  <div className="products-list">
    {products.map((product) => (
      <div key={product.id} className="products-list__product">
        <img
          src={`${process.env.PUBLIC_URL}/images/${product.path}`}
          alt=""
        />
      </div>
    ))}
  </div>
);

export default ProductsList;
