import React from 'react';
import './produtsFiltesr.scss';
import { filters, labels } from '../../v2/common/constants/constants';

const ProductsFilters = (
  {
    filterValue = filters.all,
    handleChangeFilterValue = () => {},
  }
) => (
  <div className="products-filters">
    <div className="products-filters__title">
      {labels.productsFiltersTitle}
    </div>
    <div className="products-filters__container">
      <div className="products-filters__container__filter__box">
        <div
          className={`products-filters__container__filter ${
            filterValue === filters.all ? 'products-filters__container__filter__active' : ''
          }`}
          onClick={() => handleChangeFilterValue(filters.all)}
        >
          {labels.all}
        </div>
      </div>
      <div className="products-filters__container__filter__box">
        <div
          className={`products-filters__container__filter ${
            filterValue === filters.costumes ? 'products-filters__container__filter__active' : ''
          }`}
          onClick={() => handleChangeFilterValue(filters.costumes)}
        >
          {labels.costumes}
        </div>
      </div>
      <div className="products-filters__container__filter__box">
        <div
          className={`products-filters__container__filter ${
            filterValue === filters.coats ? 'products-filters__container__filter__active' : ''
          }`}
          onClick={() => handleChangeFilterValue(filters.coats)}
        >
          {labels.coats}
        </div>
      </div>
    </div>
  </div>
);

export default ProductsFilters;
