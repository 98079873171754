import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './navbar.scss';
import { labels, paths } from '../../common/constants/constants';
import { Link } from "react-router-dom";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }

  componentDidMount() {
    document.addEventListener("click", this.detectClickOutside);
  }

  componentWillMount() {
    document.removeEventListener("click", this.detectClickOutside);
  }

  detectClickOutside = () => {
    if (this.state.isModalOpen) {
      this.setState({
        isModalOpen: false,
      });
    }
  }

  toggleModal = (value) => {
    this.setState({
      isModalOpen: typeof value === 'boolean' ? value : !this.state.isModalOpen,
    });
  }

  render() {
    return (
      <div className="navbar">
        <div className="navbar__title">
          <Link to={paths.home}>
            {labels.mainTitle}
          </Link>
        </div>
        <div className="navbar__dots" onClick={(e) => {
          e.stopPropagation();
          this.toggleModal(true);
        }}>
          <img
            src={`${process.env.PUBLIC_URL}/images/dots.png`}
            alt=""
        />
        </div>
        {this.state.isModalOpen ? (
          <div
            className="navbar__modal"
            onClick={(e) => e.stopPropagation()}
          >
            <div className={`navbar__modal__link__container ${
              this.props.currentPath === paths.home ?
                'navbar__modal__link__container__active' : ''
            }`}>
              <Link to={paths.home}>
                <div
                  className={`navbar__modal__link ${
                    this.props.currentPath === paths.home ?
                      'navbar__modal__link__active' : ''
                  }`}
                  onClick={() => this.toggleModal(false)}
                >
                  {labels.home}
                </div>
              </Link>
            </div>
            <div className={`navbar__modal__link__container ${
              this.props.currentPath === paths.products ?
                'navbar__modal__link__container__active' : ''
            }`}>
              <Link to={paths.products}>
                <div
                  className={`navbar__modal__link ${
                    this.props.currentPath === paths.products ?
                      'navbar__modal__link__active' : ''
                  }`}
                  onClick={() => this.toggleModal(false)}
                >
                  {labels.products}
                </div>
              </Link>
            </div>
            <div className={`navbar__modal__link__container ${
              this.props.currentPath === paths.contact ?
                'navbar__modal__link__container__active' : ''
            }`}>
              <Link to={paths.contact}>
                <div
                  className={`navbar__modal__link ${
                    this.props.currentPath === paths.contact ?
                      'navbar__modal__link__active' : ''
                  }`}
                  onClick={() => this.toggleModal(false)}
                >
                  {labels.contact}
                </div>
              </Link>
            </div>
            <div
              className="navbar__modal__close-container"
              onClick={() => this.toggleModal(false)}
            >
              <img src={`${process.env.PUBLIC_URL}/images/arrow-up.png`} alt="" />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Navbar;

Navbar.propTypes = {
  currentPath: PropTypes.string,
};