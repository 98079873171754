import React, { Component } from 'react';
import './products.scss';
import { filters, products } from '../../v2/common/constants/constants';
import ProductsHeader from '../../components/ProductsHeader/ProductsHeader';
import ProductsFilters from '../../components/ProductsFilters/ProductsFilters';
import ProductsList from '../../components/ProductsList/ProductsList';

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterValue: filters.all,
      filteredProducts: [...products],
    }
  }

  handleChangeFilterValue = (newValue) => {
    this.setState({
      filterValue: newValue,
      filteredProducts: newValue !== filters.all ?
        products.filter((product) => product.category === newValue) : [...products],
    });
  }

  render() {
    return (
      <div className="products">
        <ProductsHeader />
        <ProductsFilters
          filterValue={this.state.filterValue}
          handleChangeFilterValue={this.handleChangeFilterValue}
        />
        <ProductsList
          products={this.state.filteredProducts}
        />
      </div>
    );
  }
}

export default Products;
