import React from 'react';
import  './footer.scss';
import { details } from '../../common/constants/constants';

const Footer = () => (
  <div className="footer">
    <div className="footer__group">
      <img
        src={`${process.env.PUBLIC_URL}/images/clock.png`}
        alt=""
      />
        <div className="form__group__content">
          {details.workInterval}
        </div>
    </div>
    <div className="footer__group">
      <img
        src={`${process.env.PUBLIC_URL}/images/phone.png`}
        alt=""
      />
      <div className="form__group__content">
        {details.phoneNumber}
      </div>
    </div>
    <div className="footer__group">
      <img
        src={`${process.env.PUBLIC_URL}/images/facebookIcon.png`}
        alt=""
      />
      <div className="form__group__content">
        {details.facebook}
      </div>
    </div>
    <div className="footer__group">
      <img
        src={`${process.env.PUBLIC_URL}/images/location.png`}
        alt=""
      />
      <div className="form__group__content">
        {details.address}
      </div>
    </div>
  </div>
);

export default Footer;
