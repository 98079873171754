import React from "react";
import "./aboutUs.scss";
import { contactLabels, details, labels } from '../../v2/common/constants/constants';

const AboutUs = () => {
  return (
    <div className="contact">
      <div className="contact__content">
        <div className="contact__title">
          {contactLabels.firstSectionTitle}
        </div>
        <div className="contact__content__details">
          {contactLabels.firstSentence}
        </div>
        <div className="contact__content__details">
          {contactLabels.secondSentence}
        </div>
        <div className="contact__content__details">
          {contactLabels.thirdSentence}
        </div>
      </div>
      <div className="contact__more-info">
        <div className="contact__title">
          {contactLabels.secondSectionTitle}
        </div>
        <div className="contact__more-info__container">
          <div className="contact__more-info__container__contact">
            <div className="contact__more-info__container__title">
              {labels.contact}
            </div>
            <div className="contact__more-info__container__list">
              <div className="contact__more-info__container__group">
                <img
                  src={`${process.env.PUBLIC_URL}/images/clock.png`}
                  alt="" />
                <div>{details.workInterval}</div>
              </div>
              <div className="contact__more-info__container__group">
                <img
                  src={`${process.env.PUBLIC_URL}/images/phone.png`}
                  alt="" />
                <div>{details.phoneNumber}</div>
              </div>
              <div className="contact__more-info__container__group">
                <img
                  src={`${process.env.PUBLIC_URL}/images/email.png`}
                  alt="" />
                <div className="long-text">{details.email}</div>
              </div>
              <div className="contact__more-info__container__group">
                <img
                  src={`${process.env.PUBLIC_URL}/images/facebook.png`}
                  alt="" />
                <div className="long-text">{details.facebook}</div>
              </div>
              <div className="contact__more-info__container__group">
                <img
                  src={`${process.env.PUBLIC_URL}/images/instagram.png`}
                  alt="" />
                <div className="long-text">{details.instagram}</div>
              </div>
            </div>
          </div>
          <div className="contact__more-info__container__location">
            <div className="contact__more-info__container__title">
              {labels.location}
            </div>
            <div className="contact__more-info__container__list">
              <div className="contact__more-info__container__group">
                <img
                  src={`${process.env.PUBLIC_URL}/images/location.png`}
                  alt="" />
                <div>{details.address}</div>
              </div>
              <div className="contact__more-info__container__group">
                <img
                  src={`${process.env.PUBLIC_URL}/images/indications.png`}
                  alt="" />
                <div>{details.indications}</div>
              </div>
            </div>
          </div>
          <img
            className="contact__more-info__container__tailor"
            src={`${process.env.PUBLIC_URL}/images/tailor.png`}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
